<template>
    <v-container fluid class="py-0 my-0 px-0 mx-0">
        <v-row class="team-banner" justify="center" align="center" :style="`background: linear-gradient(160deg, ${team.color ? team.color : 'orange'} 50%, #bbb7b6 50%);`">
            <v-col cols="12" lg="6" align="center">
                <img
                    v-if="team.logo"
                    :src="`${assetsUrl}/${team.logo}`"
                    height="180px"
                    class="mt-3"
                    contain
                >
                <v-icon v-else style="font-size: 100px;" class="orange--text mt-3"> mdi-basketball </v-icon>
            </v-col>
            <v-col cols="12" lg="6" align="center">
                <v-row no-gutters>
                    <v-col cols="12" align="center" justify="center">
                        <match-type-choice @setType="setType" />
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="12" class="team-name" justify="center" align="center">
                        {{ team.name }}
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="5" align="center" class="mx-0 px-0">
                        {{ team.league ? team.league.title : '' }}
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="5" align="end" class="mx-0 px-0">
                        <div class="win-lose-text">
                            Νίκες
                        </div>
                        <div class="win-lose-score">
                            {{ team.seasonWins }}
                        </div>
                    </v-col>
                    <v-col cols="1" class="mx-0 px-0">
                        <v-divider
                            vertical
                            color="white"
                            class="custom-divider"
                        />
                    </v-col>
                    <v-col cols="5" align="start" class="mx-0 px-0">
                        <div class="win-lose-text">
                            Ήττες
                        </div>
                        <div class="win-lose-score">
                            {{ team.seasonLoses }}
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MatchTypeChoice from '@/components/MatchTypeChoice'
    export default {
        components: { MatchTypeChoice },
        props: {
            team: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        methods: {
            setType(option) {
                this.$emit('setType', option)
            }
        }
    }
</script>

<style scoped>
.team-name {
    font-size: 2.2rem;
    font-weight: 800;
}

.win-lose-score {
    font-size: 2.2rem;
    font-weight: 800;
}

.win-lose-text {
    font-size: 1.2rem;
}

.custom-divider {
    border-width: 1px;
    height: 70px;
}

.team-banner {
    color: white;
    font-size: 1.5rem;
}
</style>
