<template>
    <v-row class="mx-0 px-0 my-0 py-0">
        <v-col cols="12" class="mx-0 px-0" style="display: inline-flex; overflow-x: auto;">
            <v-card
                v-for="(match, index) in matches.filter(element => !element.pending).sort((a, b) => { a.round > b.round ? 1 : -1})"
                :key="index"
                style="min-width: 200px;"
                class="mx-1 py-2 px-2"
                @click="!match.postponed && !match.pending ? $router.push({ name: 'Match', params: { id: match.id } }) : null"
            >
                <v-row>
                    <v-col cols="12" align="center">
                        <v-icon color="orange"> mdi-basketball </v-icon>
                        <span class="font-weight-bold"> Round {{ match.round }}, </span>
                        <span class="font-weight-thin">
                            {{ `${match.league ? match.league.title : ''}, ` + (match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs'))) }}
                        </span>
                    </v-col>
                </v-row>
                <v-row align="center" class="my-0 secondary-font">
                    <v-col :cols="match.pending ? 4 : 3" @click.stop="goToTeam(match.team1)">
                        <v-img
                            v-if="match.team1.logo"
                            :src="`${assetsUrl}/${match.team1.logo}`"
                            width="50px"
                            height="auto"
                        />
                        <v-icon v-else style="font-size: 50px;" class="orange--text"> mdi-basketball </v-icon>
                    </v-col>
                    <v-col v-if="match.pending" cols="4" align="center" class="grey--text lighten-1 font-weight-bold px-0 rounded">
                        <v-row no-guitters>
                            <v-col cols="4" class="px-0 mx-0">
                                <v-img
                                    :src="require('@/assets/jersey-orange.svg')"
                                    height="30px"
                                    contain
                                />
                            </v-col>
                            <v-col cols="4" class="px-0 mx-0">
                                <span class="mx-1">vs</span>
                            </v-col>
                            <v-col cols="4" class="px-0 mx-0">
                                <v-img
                                    :src="require('@/assets/jersey-black.png')"
                                    height="30px"
                                    contain
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="!match.pending" cols="3" class="font-weight-bold left-rounded" :class="match.scoreTeam1 > match.scoreTeam2 ? 'winning-score custom-orange' : 'losing-score-left'">
                        <span>{{ match.scoreTeam1 }}</span>
                    </v-col>
                    <v-col v-if="!match.pending" cols="3" class="font-weight-bold right-rounded" :class="match.scoreTeam2 > match.scoreTeam1 ? 'winning-score custom-orange' : 'losing-score-right'">
                        <span>{{ match.scoreTeam2 }}</span>
                    </v-col>
                    <v-col :cols="match.pending ? 4 : 3" @click.stop="goToTeam(match.team2)">
                        <v-img
                            v-if="match.team2.logo"
                            :src="`${assetsUrl}/${match.team2.logo}`"
                            width="50px"
                            height="auto"
                        />
                        <v-icon v-else style="font-size: 40px;" class="orange--text"> mdi-basketball </v-icon>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    export default {
        props: {
            matches: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        created() {
            this.$nextTick(() => { this.getMatchesAsc() })
        },
        methods: {
            getMatchesAsc() {
                this.matches.sort(function(a, b) {
                    if (momentTimezone(a.matchDatetime).isAfter(momentTimezone(b.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
            },
            getDate(date) {
                return momentTimezone(date).tz('Europe/Athens').locale('el-GR').format('dddd DD/MM/y, HH:mm').toString()
            },
            formatMatchArray() {
                const data = []
                let index = 1
                let temp = []
                this.matches.forEach(element => {
                    temp.push(element)
                    if (index % 4 === 0 || index === this.matches.length) {
                        data.push(temp)
                        temp = []
                    }
                    index++
                })
                return data
            },
            openMap(arena) {
                window.open(`https://www.google.com/maps/place/${arena.coordinates}`, '_self', 'noreferrer')
            },
            goToTeam(team) {
                this.$router.push({ name: 'Team', params: { id: team.id } })
            },
            onTouchStart() {
                document.querySelector('body').style.overflow = 'hidden'
            },
            onTouchEnd() {
                document.querySelector('body').style.overflow = 'auto'
            }
        }
    }
</script>

<style>
.v-window__next,
.v-window__prev {
    background: rgba(0, 0, 0, 0);
    margin: 0 -5px;
}

.left-rounded {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right-rounded {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>

<style scoped>

.default-cursor {
    cursor: default;
}

.custom-orange {
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 50%, orange 100%);
}

.losing-score-right {
    outline: solid 2px orange;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.losing-score-left {
    outline: solid 2px #ef4444;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.winning-score {
    color: white;
}

.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 50%, orange 100%);
    color: white;
}
</style>
