<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <v-row>
            <v-col cols="12">
                <v-chip-group
                    v-model="option"
                    column
                    mandatory
                >
                    <v-chip
                        v-for="(item, index) in ['Μέσοι Όροι', 'Σύνολο']"
                        :key="index"
                        filter
                        filter-icon="mdi-basketball"
                        active-class="custom-orange selected-chip"
                        color="white"
                        large
                        mandatory
                        class="text-h5 font-weight-bold"
                    >
                        {{ item }}
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="(stat, index) in stats"
                :key="index"
                cols="4"
                md="2"
                class="mx-0 px-5"
                style="text-transform: capitalize;"
                align="center"
            >
                <v-card v-if="!$vuetify.breakpoint.mobile" class="elevation-1 rounded-lg">
                    <div class="custom-orange white--text text-h4 secondary-font font-weight-bold py-16">
                        {{ option === 0 && matchesPlayed > 0 ? (stat.total / matchesPlayed).toFixed(1) : stat.total }}
                    </div>
                    <div class="text-h5 font-weight-bold py-8">
                        {{ stat.value === 'blockedShots' ? 'blocks' : stat.value }}
                    </div>
                </v-card>
                <div v-else class="circle-border">
                    <div class="secondary-font text-h6">
                        {{ option === 0 ? (stat.total / matchesPlayed).toFixed(1) : stat.total }}
                    </div>
                    <div class="custom-orange-text">
                        {{ getText(stat.value) }}
                    </div>
                </div>
            </v-col>
        </v-row>
        <players-stats :players="players" :option="option" />
    </v-container>
</template>

<script>
    import PlayersStats from '@/components/PlayersStats'
    export default {
        components: { PlayersStats },
        props: {
            players: {
                type: Array,
                default: null
            },
            matchesPlayed: {
                type: Number,
                default: 1
            },
            type: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                option: 0,
                stats: [
                    { value: 'points', total: 0 },
                    { value: 'rebounds', total: 0 },
                    { value: 'assists', total: 0 },
                    { value: 'steals', total: 0 },
                    { value: 'turnovers', total: 0 },
                    { value: 'blockedShots', total: 0 }
                ]
            }
        },
        watch: {
            players: {
                handler() {
                    this.getTotalTeam()
                },
                deep: true
            }
        },
        created() {
            this.getTotalTeam()
        },
        methods: {
            getTotalTeam() {
                this.stats.forEach(stat => {
                    stat.total = 0
                    this.players.forEach(player => {
                        player.stats.forEach(match => {
                            stat.total += match[stat.value]
                        })
                    })
                })
            },
            getText(key) {
                if (key === 'blockedShots') {
                    return 'blocks'
                } else if (key === 'rebounds') {
                    return 'reb.'
                } else if (key === 'turnovers') {
                    return 'turn.'
                } else {
                    return key
                }
            }
        }
    }
</script>

<style scoped>
.circle-border {
    border-radius: 50%;
    border: 3px solid orange;
    width: 90px;
    height: 90px;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.text-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    letter-spacing: normal !important;
    font-family: Mansalva, Arial, sans-serif !important;
}

.text-h4 {
    font-size: 2.125rem !important;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
}

.stat-label {
    color: #757578 !important;
    font-size: 1.2rem;
}

.top-arc {
    border-width: 2px;
    border-radius: 10px 5% / 20px 25em 30px 35em;
    border-color: orange;
}

.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: white;
}
</style>
