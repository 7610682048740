<template>
    <v-chip-group
        v-model="option"
        column
        mandatory
    >
        <v-chip
            v-for="(item, index) in ['Placements', 'Regular Season', 'Play Offs', 'Cup']"
            :key="index"
            filter
            filter-icon="mdi-basketball"
            active-class="custom-orange selected-chip"
            color="white"
            mandatory
            class="text-h5 font-weight-bold"
        >
            <v-row align="center" justify="center">
                <v-col cols="12" align="center">
                    {{ item }}
                </v-col>
            </v-row>
        </v-chip>
    </v-chip-group>
</template>

<script>
    export default {
        data() {
            return {
                option: 1
            }
        },
        watch: {
            option: {
                handler(value) {
                    this.$emit('setType', this.option)
                }
            }
        }
    }
</script>

<style>

.v-slide-group__content {
    justify-content: center !important;
}

</style>

<style scoped>
.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: white;
}

.text-h5 {
    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: normal !important;
    font-family: Mansalva, Arial, sans-serif !important;
}
</style>
