<template>
    <v-container fluid no-gutters class="my-0 py-0">
        <schedule-carousel v-if="matches.length" :matches="matches" />
        <v-row>
            <v-col cols="12" class="px-0">
                <template>
                    <v-data-table
                        v-if="type !== 'cup' && type !== 'playoffs'"
                        :headers="headers"
                        :items="ranking"
                        class="elevation-1 rounded-lg py-3 px-3 text-center"
                        hide-default-footer
                        disable-sort
                        :mobile-breakpoint="0"
                        :items-per-page="-1"
                    >
                        <template v-slot:item.club="{ item }">
                            <v-row align="center" justify="center">
                                <v-col cols="3">
                                    <v-img
                                        v-if="item.imageUrl"
                                        :src="`${assetsUrl}/${item.imageUrl}`"
                                        height="40px"
                                        width="40px"
                                        contain
                                    />
                                    <v-icon v-else :style="$vuetify.breakpoint.mobile ? '' : 'font-size: 40px'" class="orange--text"> mdi-basketball </v-icon>
                                </v-col>
                                <v-col cols="9">
                                    <span class="font-weight-bold">{{ item.name }}</span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.gp="{ item }">
                            <v-row align="center">
                                <v-col cols="12">
                                    {{ (item.won + item.lost) }}
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.win_percentage="{ item }">
                            <v-row align="center">
                                <v-col cols="12">
                                    {{ `${(item.won + item.lost) > 0 ? (item.won / (item.won + item.lost) * 100).toFixed(1) : 0}%` }}
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.position="{ item }">
                            <v-row align="center">
                                <v-col cols="12">
                                    {{ ranking.indexOf(item) + 1 }}
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                    <v-row v-else align="center">
                        <v-col cols="12" style="position: relative;" class="d4" align="center">
                            <v-card v-if="cupRound === 0">
                                <v-card-text>
                                    Δεν υπάρχει διαθέσιμη πληροφορία αυτή τη στιγμή.
                                </v-card-text>
                            </v-card>
                            <table v-else>
                                <tr>
                                    <th
                                        v-for="i in cupRound"
                                        :key="i"
                                    >
                                        <v-card
                                            color="custom-orange"
                                            class="white--text"
                                        >
                                            {{ getCupRoundName(i) }}
                                        </v-card>
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        v-for="i in cupRound"
                                        :key="i"
                                        style="vertical-align: baseline;"
                                    >
                                        <graph-match-card
                                            v-for="match in matches.filter((element) => element.round === i)"
                                            :key="match.id"
                                            class="my-5"
                                            :match="match"
                                        />
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ScheduleCarousel from '@/components/ScheduleCarousel'
    import GraphMatchCard from '@/components/GraphMatchCard'

    export default {
        components: { ScheduleCarousel, GraphMatchCard },
        props: {
            type: {
                type: String,
                default: null
            },
            showFilters: {
                type: Boolean,
                default: true
            },
            ranking: {
                type: Array,
                default: null
            },
            matches: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com',
                headers: [
                    { text: 'Club', value: 'club', width: '200px', align: 'start' },
                    {
                        text: 'Pts.',
                        value: 'standing',
                        width: '30px',
                        align: 'center'
                    },
                    { text: 'GP', value: 'gp', width: '50px', align: 'center' },
                    { text: 'Won', value: 'won', width: '50px', align: 'center' },
                    { text: 'Lost', value: 'lost', width: '50px', align: 'center' },
                    { text: 'Win(%)', value: 'win_percentage', width: '50px', align: 'center' },
                    { text: 'Pts+', value: 'pointsPlus', width: '50px', align: 'center' },
                    { text: 'Pts-', value: 'pointsMinus', width: '50px', align: 'center' },
                    { text: '+/-', value: 'pointsDiff', width: '50px', align: 'center' }
                ],
                cupRound: 0
            }
        },
        watch: {
            matches: {
                handler() {
                    if (this.type === 'cup' || this.type === 'playoffs') {
                        this.setCupRound()
                    }
                },
                deep: true
            }
        },
        created() {
            // if (this.type === 'cup') {
            //     this.setCupRound()
            // }
        },
        methods: {
            getCupRoundName(i) {
                if (i === this.cupRound) {
                    return 'Finals'
                } else if (i === this.cupRound - 1) {
                    return 'Semi-Finals'
                } else if (i === this.cupRound - 2) {
                    return 'Quarter-Finals'
                } else {
                    return `Round ${i}`
                }
            },
            setCupRound() {
                this.cupRound = this.matches.reduce((prev, current) => {
                    return (prev && prev.round > current.round) ? prev : current
                }, 0).round
            }
        }
    }
</script>

<style scoped>
.bg {
    position: absolute;
    background: url('../assets/basketball-bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 100%;
}

.d4 {
    position: relative;
    max-height: 100%;
    overflow: auto;
}
</style>
