<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <v-card v-if="matches.length === 0">
            <v-card-text>
                Δεν υπάρχει διαθέσιμη πληροφορία αυτή τη στιγμή.
            </v-card-text>
        </v-card>
        <v-container v-else fluid no-gutters class="mx-0 px-0">
            <v-row class="mt-1">
                <v-col cols="8" md="2">
                    <v-card class="custom-date custom-orange py-2">
                        <span>Επόμενη αγωνιστική</span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                class="py-0 my-0 font-weight-bold secondary-font"
            >
                <v-col cols="12" class="py-1">
                    <match-card :match="getMatchesAsc(matches.filter(match => match.pending))[0]" />
                </v-col>
            </v-row>
            <v-row class="mt-1">
                <v-col cols="7" md="2">
                    <v-card class="custom-date custom-orange py-2">
                        <span>Αποτελέσματα</span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-for="match in getMatchesDesc(matches.filter(match => !match.pending))"
                :key="match.id"
                class="py-0 my-0 font-weight-bold secondary-font"
            >
                <v-col cols="12" class="py-1">
                    <match-card :match="match" />
                </v-col>
            </v-row>
            <v-row class="mt-1">
                <v-col cols="8" md="2">
                    <v-card class="custom-date custom-orange py-2">
                        <span>Επόμενες αγωνιστικές</span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-for="(match, index) in getMatchesAsc(matches.filter(match => match.pending))"
                :key="match.id"
                class="py-0 my-0 font-weight-bold secondary-font"
            >
                <v-col cols="12" class="py-1">
                    <match-card v-if="index > 0" :match="match" />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import MatchCard from '@/components/MatchCard'
    import momentTimezone from 'moment-timezone'

    export default {
        components: { MatchCard },
        props: {
            matches: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            getMatchesDesc(matches) {
                matches.sort(function(a, b) {
                    if (momentTimezone(b.matchDatetime).isAfter(momentTimezone(a.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
                return matches
            },
            getMatchesAsc(matches) {
                matches.sort(function(a, b) {
                    if (momentTimezone(a.matchDatetime).isAfter(momentTimezone(b.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
                return matches
            }
        }
    }
</script>

<style scoped>
.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background-color: orange;
    color: white;
}
</style>
