<template>
    <v-card class="px-2" :width="$vuetify.breakpoint.mobile ? '200px' : '300px'">
        <v-row
            :style="match.scoreTeam2 > match.scoreTeam1 ? 'filter: grayscale(1);' : ''"
            align="center"
            :class="match.scoreTeam2 > match.scoreTeam1 ? '' : 'custom-orange-text'"
        >
            <v-col cols="12" lg="8" align="center">
                <img
                    :src="`${assetsUrl}/${match.team1.logo}`"
                    height="30px"
                    contain
                >
                {{ match.team1.name }}
            </v-col>
            <v-col cols="12" lg="4" align="center">
                <div>{{ match.scoreTeam1 }}</div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row
            :style="match.scoreTeam1 > match.scoreTeam2 ? 'filter: grayscale(1);' : ''"
            align="center"
            :class="match.scoreTeam1 > match.scoreTeam2 ? '' : 'custom-orange-text'"
        >
            <v-col cols="12" lg="8" align="center">
                <img
                    :src="`${assetsUrl}/${match.team2.logo}`"
                    height="30px"
                    contain
                >
                {{ match.team2.name }}
            </v-col>
            <v-col cols="12" lg="4" align="center">
                {{ match.scoreTeam2 }}
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        props: {
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com'
            }
        }
    }
</script>
